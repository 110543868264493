.document-resource-display {
	--image-width: 5rem;
	width: calc(var(--image-width) + 1rem);
	padding: 0;

	user-select: none;
}

.document-resource-display__column {
	width: 100%;
}

.document-resource-display__name {
	white-space: nowrap;
	text-overflow: ellipsis;

	max-width: 100%;
	overflow: hidden;
}

.resource-preview {
	width: 90vw;
	max-width: calc(100vw - 2rem);
	height: 95vh;
	max-height: none;

	--modal__body---padding: 1rem;
}

.resource-preview__display {
	width: 100%;
	height: 100%;
	border-radius: calc(var(--general__tile---border-radius) - var(--modal__body---padding));
}

/* Image Container */

.image-container {
	--size: var(--image-width);
	width: var(--size);
	height: var(--size);

	overflow: hidden;

	position: relative;
}

.image-container img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.image-container--wide img {
	width: 100%;
	height: auto;
}

.image-container--tall img {
	width: auto;
	height: 100%;
}

.image-placeholder {
	background-color: #eee;

	width: 100%;
	height: 100%;
	border-radius: 1rem;
}
