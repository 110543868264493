/* Expand Button */

.expenses-expand-button__icon {
	transition: 200ms ease-out;
}

.expenses-expand-button:hover .expenses-expand-button__icon {
	transform: translateX(0.25rem);
}

/* Progress Bar */

.budget-progress-bar {
	background-color: #eee;

	width: 100%;
	height: 2rem;
	--border-radius: 0.5rem;
	border-radius: var(--border-radius);

	position: relative;
}

.budget-progress-bar__expense-bars,
.budget-progress-bar__expense-tooltips {
	height: 100%;

	transition: width 250ms ease-out;

	position: absolute;
	top: 0;
	left: 0;
}

.budget-progress-bar__expense-bars {
	border-radius: var(--border-radius);

	z-index: 1;
	overflow: hidden;
}

.budget-progress-bar__expense-tooltips {
	z-index: 2;
}

/* Individual Budget Progress Bars */

.budget-category-progress-bar {
	flex-grow: 1;
	width: 1rem;
	background-color: #eee;
	border-radius: 0.25rem;
}

.budget-category-progress-bar__bar {
	width: 100%;
	background-color: var(--category-color);
	border-radius: 0.25rem;
}

.budget-category-progress-bar__tooltip-base {
	background-color: var(--category-color);

	--size: 1rem;
	width: var(--size);
	height: var(--size);
	border-radius: 50%;
}

/* Budget Pie */

.budget-pie-container {
	width: 40%;
	height: 100%;
}

.budget-pie-container svg {
	overflow: visible;
}

/* Budget Breakdown */

.budget-breakdown__table__heading > *::after {
	display: none;
}
