.drawer {
	--drawer--vertical---width: 80vw;
}

@media (min-width: 1000px) {
	.drawer {
		--drawer--vertical---width: 60vw;
	}
}

.drawer__body {
	padding-bottom: 3rem;
}

.drawer__top-bar {
	color: #777;
	padding-bottom: 0.5rem;
}

.progress-bar {
	position: relative;
}

.progress-bar__step {
	flex-grow: 1;
}

.progress-bar__step__pill {
	--status-color: #eee;
	background-color: var(--status-color);

	height: 0.75rem;
	border-radius: 10rem;
}

.progress-bar__step--success .progress-bar__step__pill {
	--status-color: var(--success-color);
}

.progress-bar__step--failure .progress-bar__step__pill {
	--status-color: var(--danger-color);
}

.progress-bar__next-step {
	font-size: 0.8rem;
	white-space: nowrap;

	position: absolute;
	top: calc(100% + 0.25rem);
	left: 50%;
	transform: translateX(-50%);
}

.collapsed-status__button {
	--size: 2rem;
	width: var(--size);
	height: var(--size);

	padding: 0;
	border: none;
	border-radius: 50%;
}

.collapsed-status__button:hover,
.collapsed-status__button:focus {
	background-color: #eee;
}

.status-update-buttons__tooltip,
.contact-information__tooltip,
.form-tooltip {
	--tooltip---font-size: var(--tooltip---font-size);
	--tooltip---color: var(--general---color);
	--tooltip__body---color: var(--general---color);
	--tooltip---background-color: var(--general---background-color);
}

.contact-information__tooltip,
.form-tooltip {
	--tooltip---border-radius: 1rem;
	--tooltip__body---padding: 1rem;
}

.contact-information__tooltip {
	width: 90vw;
	min-width: 0;
	max-width: 32rem;
}

.overview-box {
	padding-right: 1rem;
	border-radius: 2rem;
	border-top-left-radius: 10rem;
	border-bottom-left-radius: 10rem;
	margin: 1rem 0;
}

.quick-stat {
	background-color: #f2f9ff;

	padding: 0.4rem 1rem;
	border-radius: 1rem;
	flex-grow: 1;
}

.quick-stat__value {
	font-size: 1.1rem;
	text-align: right;
}

.nav {
	padding-left: 1rem;
	border-bottom: 1px solid #eee;
}

.nav__button {
	padding: 0.4rem 0.75rem 0.3rem;
	border-bottom: none;
	border-radius: 1rem 1rem 0 0;
}

.nav__button__num-notes {
	font-size: 0.9em;
	color: #fff;
	background-color: var(--admissions-success-alert-color);

	padding: 0 0.4rem;
	border-radius: 10rem;
}

.form-submission-data-section {
	--section__body---padding: 0.25rem;
}
