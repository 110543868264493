/* Search */

.quick-actions__search-container {
	transition: opacity 150ms ease-out;

	position: relative;
	z-index: 50;
}

.quick-actions__search {
	font-size: 1.25rem;

	width: 100%;
	--global-search__simple__search-bar---width: 100%;
}

.quick-actions__search > form > div:first-child > div:first-child {
	width: 100%;
}

.quick-actions__search__input {
	border-color: transparent;
	border-radius: 2rem;
	padding: 1rem;
}

.quick-actions__search__input:focus {
	border-color: var(--general__field__input--focus---border-color);
}

/* Button dropdown */

.button-dropdown-tooltip {
	--tooltip---border-radius: 1.25rem;
}

.button-dropdown-column button {
	white-space: nowrap;
	text-align: center;

	width: 100%;
	border-width: 2px;
	border-radius: var(--tooltip---border-radius);
}

.button-dropdown-column button:not(:first-child) {
	border-top-width: 1px;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}

.button-dropdown-column button:not(:last-child) {
	border-bottom-width: 1px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}
