.relationship-fields,
.address-same-as,
.address-line {
	width: 100%;
	max-width: calc(var(--general__field---width) * 2 + var(--general--spaced---horizontal-spacing));
}

.radio-buttons {
	--radio-field---width: 30rem;
}

.radio-buttons--relationship-type {
	--radio-field---width: var(--general__field---width);
}

.boolean-button {
	--boolean-field---width: fit-content;
}

.radio-buttons label,
.boolean-button label {
	padding-left: 1rem;
	padding-right: 1rem;
}

.phone-validation--error {
	padding: 0.5rem 0.75rem;
	border: 1px dashed var(--danger-color);
	border-radius: 0.5rem;
}

.field-hint {
	--size: 16px;
	--icon---size: 12px;
	width: var(--size);
	height: var(--size);
	border-radius: 50%;

	position: relative;
}

.field-hint--failure {
	background-color: var(--danger-color);
}

.field-hint__icon {
	color: #fff;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
