.data-list-tile {
	position: relative;
}

.data-list-tile__body {
	padding: 0.3rem 0.25rem 0.3rem 1rem;
}

.data-list-tile__number {
	color: var(--verita-blue);
	font-size: 3rem;
}

.data-list-tile__label {
	font-size: 1.5rem;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.data-list-tile__expand-icon {
	color: var(--verita-blue);

	transition: 200ms ease-out;
}

.data-list-tile:hover .data-list-tile__expand-icon {
	transform: translateX(0.25rem);
}
