.date-of-birth {
	position: relative;
}

.date-of-birth__age {
	color: #777;
	font-size: 0.8rem;
}

.date-of-birth--editing .date-of-birth__age {
	position: absolute;
	bottom: 0;
	left: 0.25rem;
}
