.progress-bar-tile {
	position: relative;
	overflow: hidden;
	--progress-gradient---color: #2c71af70;
}

.progress-bar-tile::after {
	content: "";
	background-image: linear-gradient(90deg, transparent -30%, var(--progress-gradient---color));

	width: var(--progress);
	max-width: 100%;
	height: 100%;
	border-radius: var(--general__tile---border-radius);
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;

	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;

	transition: width var(--refresh-interval) linear;
}

.progress-bar-tile > * {
	position: relative;
	z-index: 2;
}
