.form-section-container--create {
	min-height: calc(100vh - var(--header---height));
}

.form-section__body {
	display: flex;
	flex-direction: column;
}

.form-section__column {
	width: fit-content;
}

.field-set__legend {
	font-size: 1rem;
}

.personality-fields {
	max-width: 45rem;
	--text-field---width: 100%;
	--radio-field---width: 100%;
}
