.progress-bar-background {
	content: "";
	background-image: linear-gradient(90deg, transparent -30%, #2c71af70);

	width: var(--progress);
	max-width: 100%;
	height: 100%;
	border-radius: var(--general__tile---border-radius);

	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;

	transition: width var(--refresh-interval) linear;
}
