.lesson-plans-week-course-section-preview-modal {
	--modal--large---width: 95vw;
	--modal--large---height: fit-content;
}

.lesson-plans-week-course-section-preview-modal__items-row {
	padding-right: 1.5rem;
}

.lesson-plans-week-course-section-preview-modal__item {
	flex-grow: 0.2;
}

.lesson-plans-week-course-section-preview-modal__item:not(:last-child) {
	border-right: 1px solid #eee;
}

.lesson-plans-week-course-section-preview-modal__item:last-child {
	padding-right: 1.5rem;
}
