.main-row {
	height: calc(100vh - var(--header---height) - 2 * var(--main---padding-top));
}

.controller-tile {
	width: 25%;
	min-width: 25rem;
	height: 100%;

	overflow: scroll;
}

.preview-tile--full {
	height: 100%;

	overflow: scroll;
}
