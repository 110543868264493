.button-dropdown-column button {
	white-space: nowrap;
	text-align: center;

	width: 100%;
}

.button-dropdown-column button:not(:first-child) {
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}

.button-dropdown-column button:not(:last-child) {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

/* Parents */

.parent-card {
	--image-size: 4rem;
	--border-width: 3px;

	background-image: linear-gradient(
		var(--light-blue) 0 calc(var(--image-size) - var(--border-width)),
		transparent 0 100%
	);

	border: var(--border-width) solid var(--light-blue);
	--border-radius: 1rem;
	border-radius: var(--border-radius);
	border-top-left-radius: calc(var(--image-size) / 2);

	position: relative;

	overflow: visible;
}

/* Specificity override */

.parent-card > .parent-card__header {
	background-color: var(--light-blue);

	padding: 0.25rem 0.5rem;
	border-top-left-radius: calc(var(--image-size) / 2);
	border-top-right-radius: var(--border-radius);
}

.parent-card > .parent-card__body {
	padding: 0.5rem 1rem;
	border-bottom-left-radius: var(--border-radius);
	border-bottom-right-radius: var(--border-radius);
}

.parent-card__edit-button-container {
	opacity: 0;
	pointer-events: none;

	background-color: var(--light-blue);

	height: 100%;
	padding-left: 0.75rem;

	position: absolute;
	top: 0;
	right: 0;

	transition: 150ms linear;
}

.parent-card:hover .parent-card__edit-button-container {
	opacity: 1;
	pointer-events: all;
}

/* Addresses */

.address-card {
	--border-radius: 1rem;
}

.address-card__header {
	background-color: var(--light-blue);

	padding: 0.25rem 0.5rem;
	border-top-left-radius: var(--border-radius);
	border-bottom-left-radius: var(--border-radius);
}

.address-card__body {
	padding: 0.25rem 1.25rem 0.5rem 0.75rem;
	border: 3px solid var(--light-blue);
	border-left: none;
	border-top-right-radius: var(--border-radius);
	border-bottom-right-radius: var(--border-radius);
}

/* Notes */

/* TODO clean up */
.notes-scrolling-container,
.notes-scrolling-container > *,
.notes-scrolling-container > * > * {
	height: 100%;
}
