.table-cell--response {
	text-align: center;
}

.response {
	font-size: 0.9rem;

	padding: 0.3rem 0.5rem;
	border-radius: 0.5rem;
	display: inline-block;
}

.response--yes {
	color: #fff;
	background-color: var(--success-color);
}

.response--no {
	color: #fff;
	background-color: var(--danger-color);
}

.response--unknown {
	background-color: #eee;
}
