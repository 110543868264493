.simple-note {
	--background-color: #fefde0;

	background-color: var(--background-color);

	flex-shrink: 0;
	width: 12rem;
	height: 7rem;
	padding: 1rem;
	padding-bottom: 0.25rem;
	border: 1px solid #e9e9c9;
	border-radius: 1rem;
}

.simple-note__body {
	width: 100%;
	height: 100%;
	overflow: hidden;

	position: relative;
}

.simple-note__body__overflow-overlay {
	background-image: linear-gradient(transparent, var(--background-color));

	width: 100%;
	height: 2rem;

	position: absolute;
	bottom: 0;
	left: 0;
}

.simple-note__timestamp {
	font-size: 0.8rem;
	opacity: 0.6;
}
