.mobile-viewing-in-development {
	background-color: #fff;

	width: 100vw;
	height: 100vh;

	position: fixed;
	top: 0;
	left: 0;
	z-index: 10000000;
}

@media (min-width: 900px) {
	.mobile-viewing-in-development {
		display: none;
	}
}
