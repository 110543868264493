.student-document-modal {
	width: 90vw;
	max-width: calc(100vw - 2rem);
	height: 95vh;
	max-height: none;

	overflow: hidden;

	--modal__body---padding: 1rem;
}

.student-document-modal__display {
	width: 100%;
	height: 100%;
	border-radius: calc(var(--general__tile---border-radius) - var(--modal__body---padding));
}
