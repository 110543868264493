.image-container {
	overflow: hidden;
	position: relative;
}

.image-container img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.image-container.image-container--wide img {
	width: auto;
	height: 100%;
}

.image-container.image-container--tall img {
	width: 100%;
	height: auto;
}
