/* Attendance */

.attendance-stat {
	color: var(--status-color);
	background-color: var(--status-background-color);

	padding: 0.5rem 0.75rem;
	border: 1px solid var(--status-background-color);
	border-radius: 0.5rem;
}

.attendance-stat--plain {
	border: 1px solid #eee;
}

.attendance-stat--success {
	color: #fff;
	background-color: var(--success-color);
}

.attendance-stat--warning {
	color: var(--general---color);
	background-color: var(--warning-color);
}

.attendance-stat--danger {
	color: #fff;
	background-color: var(--danger-color);
}

.attendance-calendar__icon {
	background-color: transparent; /* Set in JSX */

	--size: 0.5rem;
	width: var(--size);
	height: var(--size);
	border-radius: 50%;
}

/* Courses */

.course-section-link {
	font-size: 1.5rem;
	--internal-link---color: #fff;
	--internal-link--hover-focus---color: #fff;
	--internal-link--active---color: #fff;
	--internal-link---text-decoration: none;
	--internal-link--hover-focus---text-decoration: none;
	--internal-link--active---text-decoration: none;
	flex-shrink: 0;

	width: 7rem;
	height: 5.5rem;
	padding: 0.25rem;
	border-radius: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.course-section-link__text {
	text-align: center;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	line-clamp: 2;
}

/* Snapshot */

.academic-snapshot {
	--purple: #79388f;

	--background-color: var(--light-blue);
	--border-radius: 1rem;

	position: relative;
}

.academic-snapshot__header,
.academic-snapshot__body {
	background-color: var(--background-color);
}

.academic-snapshot__header {
	background-color: var(--background-color);

	border-top-left-radius: var(--border-radius);
	border-top-right-radius: var(--border-radius);
}

.academic-snapshot__body {
	border-bottom-left-radius: var(--border-radius);
	border-bottom-right-radius: var(--border-radius);
}

.academic-snapshot__button-container {
	background-color: #fff;

	--section---horizontal-padding: 1.5rem;
	--section---bottom-padding: 1rem;
	width: calc(100% + var(--section---horizontal-padding) * 2);
	padding: 0.75rem;
	border-radius: var(--border-radius);
	margin-left: calc(-1 * var(--section---horizontal-padding));
	margin-bottom: calc(-1 * var(--section---bottom-padding));
}

.academic-snapshot__button {
	color: #fff;
	background: radial-gradient(79.69% 102.24% at 100% 100.11%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
		radial-gradient(89.7% 115.09% at 3.43% 2.75%, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0)), var(--purple);

	width: 100%;
	border: none;
	border-radius: var(--border-radius);
}

/* Notes */

/* TODO clean up */
.notes-scrolling-container,
.notes-scrolling-container > *,
.notes-scrolling-container > * > * {
	height: 100%;
}
