.tile {
	background-color: var(--general---background-color);
	box-shadow: var(--general---box-shadow);

	--section---border-radius: var(--general__tile---border-radius);
}

.tile > :first-child {
	border-top-right-radius: var(--general__tile---border-radius);
	border-top-left-radius: var(--general__tile---border-radius);
}

.tile > :last-child {
	border-bottom-right-radius: var(--general__tile---border-radius);
	border-bottom-left-radius: var(--general__tile---border-radius);
}

.tile__header {
	padding: 1rem 1.5rem 0;
}

.tile__header--primary {
	background-color: var(--verita-blue);
	padding-bottom: 0.75rem;
	color: #fff;
}

.tile__footer {
	padding: 0.5rem 1.5rem 1rem;
}
