.admissions-stats {
	margin: 1rem 0 1.5rem;
}

.nav {
	width: calc(100% - 5rem);

	padding-left: 1rem;
	border-bottom: 1px solid #ddd;
	margin-bottom: 1rem;
}

.nav-link,
.nav-link:hover,
.nav-link:focus {
	text-decoration: none;
}

.nav-link {
	padding: 0.5rem 0.75rem 0.4rem;
	border: 1px solid #ddd;
	border-bottom: none;
	border-radius: 1rem 1rem 0 0;
}

.nav-link--reports {
	background-image: linear-gradient(135deg, var(--verita-blue), var(--verita-green));
	color: #fff !important;
	border: none;
}

.nav-link--location-active,
.nav-link:active {
	color: #fff;
	--nav-link--location-active---color: #fff;
	background-image: none;
	background-color: var(--primary-color);
	border-color: var(--primary-color);
}
