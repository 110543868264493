.payment-status {
	font-size: 1rem;

	padding: 0.1rem 0.75rem;
	border-radius: 0.5rem;
}

.payment-status--paid {
	color: #fff;
	background-color: var(--success-color);
}

.payment-status--unpaid {
	background-color: #eee;
}

.payment-status--overdue {
	color: #fff;
	background-color: var(--danger-color);
}
