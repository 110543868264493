.person-icon__image-container {
	width: var(--image---size);
	height: var(--image---size);
	border-radius: 50%;
	overflow: hidden;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	position: relative;
}

.person-icon__image-container--no-image {
	font-size: calc(var(--image---size) * 3 / 8);
	color: #fff;
	background-image: radial-gradient(circle at center, #bbb 50%, #777);
}

.person-icon__image {
	width: 100%;
	height: 100%;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.person-icon__image img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.person-icon__image.image-container--wide img {
	width: auto;
	height: 100%;
}

.person-icon__image.image-container--tall img {
	width: 100%;
	height: auto;
}

.person-icon__name {
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 100%;
	overflow: hidden;
}

/* Student/Employee Preview */

.person-preview-tooltip {
	--tooltip---color: var(--general---color);
	--tooltip---background-color: #fff;

	max-width: 18rem;
	text-align: left;
}

.person-preview__pill {
	background-color: var(--light-blue);
	padding: 0 1rem;
	border-radius: 5rem;
}

.person-preview__one-line {
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 100%;
	overflow: hidden;
}
