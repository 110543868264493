.interview-meeting-time {
	width: 100%;
	max-height: 22rem;
}

.interview-meeting-time__right-column {
	flex-grow: 1;
	min-width: 10rem;
}

.interview-meeting-time__calendar__tile {
	aspect-ratio: 1/1;
}

.interview-meeting-time__times {
	width: 100%;
	height: 100%;
	padding-right: 1rem;
	overflow: scroll;
}

.interview-meeting-time__times__no-results {
	color: #777;
	height: 100%;
}
