.form__action-row {
	pointer-events: none;

	width: fit-content;
	margin-left: auto;

	position: sticky;
	bottom: 1rem;
	z-index: 10;
}

.form__action-row > * {
	pointer-events: auto;
}

.form__column {
	width: fit-content;
}
