.admissions__wrapper {
	position: relative;
}

.admissions__notification {
	background-color: var(--admissions-success-alert-color);

	--size: 0.9rem;
	width: var(--size);
	height: var(--size);
	border-radius: 50%;

	position: absolute;
	top: -0.5rem;
	right: -0.5rem;
}

.admissions__notification__text {
	color: #fff;
	font-size: 10px;
	line-height: 1;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
