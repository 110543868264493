.home-room-section-link {
	font-size: 1.3rem;
}

.lesson-plan-day-icon {
	--size: 1.4rem;

	font-size: calc(0.8 * var(--size));

	width: var(--size);
	height: var(--size);
	border-radius: 50%;
	border: 1px solid var(--verita-blue);

	position: relative;
}

.lesson-plan-day-icon--all {
	color: #fff;
	background-color: var(--success-color);
	border-color: var(--success-color);
}

.lesson-plan-day-icon--partial {
	background-color: var(--warning-color);
	border-color: var(--warning-color);
}

.lesson-plan-day-icon__text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
