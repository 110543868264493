.left-bar {
	height: calc(100vh - var(--header---height) - 2 * var(--main---padding-top));
	border-right: 1px solid #ddd;

	position: sticky;
	top: calc(var(--header---height) + var(--main---padding-top));
	z-index: 100;
}

.left-bar__nav__link {
	font-size: 1.25rem;

	padding-left: 0.25rem;
	position: relative;
	--nav-link---text-decoration: none;
	--nav-link--location-active---color: var(--verita-blue);
	--nav-link--active---color: var(--verita-blue);
}

.left-bar__nav__link::before {
	content: ">";

	opacity: 0;

	position: absolute;
	top: 50%;
	right: 100%;
	transform: translateY(-50%);
}

.left-bar__nav__link--location-active::before {
	opacity: 1;
}
