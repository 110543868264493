.admissions-stat {
	background-color: #f6f6f6;

	padding: 1rem 1.5rem;
	border-radius: 1rem;
	--horizontal-spacing: var(--general--spaced---horizontal-spacing);
	--num-stats: 4;
	width: calc((100% - var(--horizontal-spacing) * (var(--num-stats) - 1)) / var(--num-stats));
}

.admissions-stat__label {
	color: #777;

	margin-bottom: 0.5rem;
}

.admissions-stat__trend--up {
	color: var(--success-color);
}

.admissions-stat__trend--down {
	color: var(--verita-colors-orange);
}

.admissions-stat__trend__value {
	font-size: 0.8rem;
}

.admissions-stat__value {
	font-size: 2.5rem;
	text-align: right;
}
