.nav-link {
	--nav-link---text-decoration: none;
	--nav-link--hover-focus---text-decoration: none;
	--nav-link--active---text-decoration: none;

	--nav-link--active---color: var(--verita-blue);

	padding: 0.75rem 1.5rem;
	border-width: 1px;
	border-style: solid;
	border-color: transparent;
	border-radius: 10rem;
}

.nav-link--location-active {
	--nav-link--location-active---color: var(--verita-blue);
	background-color: var(--light-blue);
	border-color: var(--verita-blue);
}
