.resources-row-section {
	position: relative;

	--section__body---padding: 0.25rem 1rem 0.25rem 2.5rem;
}

.resources-row-section__icon-label-column {
	position: absolute;
	top: 0;
	left: 0.5rem;
}

.resources-row-section__label {
	width: fit-content;
	writing-mode: vertical-lr;
}
