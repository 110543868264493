.calendar-tile {
	--section__body---padding: 1rem 1.5rem;
}

.calendar-tile__calendar {
	width: 100%;
}

.expand-full-calendar-button {
	font-size: 1.5rem;

	--size: 2rem;
	width: var(--size);
	height: var(--size);
	border-radius: 50%;

	position: relative;
}

.expand-full-calendar-button:hover {
	background-color: #f4f4f4;
}

.expand-full-calendar-button__icon {
	display: block;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotateY(0.5turn);
}

.create-event-row {
	padding-top: 0.5rem;

	position: sticky;
	bottom: 0;
	z-index: 10;
}
