html {
	--placeholder: 0;

	--main---background-color: #fff;

	--admissions-success-alert-color: #96d35f;
}

.styles_tile__6jFKY,
.tile__oWIAT00G {
	--section---border-radius: 1.5rem;
}

main {
	padding-bottom: 0.75rem !important;
}

footer {
	display: none;
}
