.navigation-footer {
	background-color: #fffd;
	backdrop-filter: blur(2px) saturate(200%);

	width: 100%;
	--vertical-padding: 0.75rem;
	--horizontal-padding: 1rem;
	padding: var(--vertical-padding) var(--horizontal-padding);
	border-top: 1px solid #eee;
	border-radius: 0 0 var(--enrollment-application__content---border-radius)
		var(--enrollment-application__content---border-radius);

	position: sticky;
	bottom: 0;
}

@media (min-width: 600px) {
	.navigation-footer {
		--horizontal-padding: 1rem;
	}
}

@media (min-width: 800px) {
	.navigation-footer {
		--horizontal-padding: 1.5rem;
	}
}

@media (min-height: 600px) {
	.navigation-footer {
		--vertical-padding: 1.25rem;
	}
}

@media (min-height: 800px) {
	.navigation-footer {
		--vertical-padding: 2rem;
	}
}
