html {
	--document-resource-gray: #f8f7f9;
}

.document-resource-folder-display {
	padding: 1rem 1.25rem;
	border-radius: 1rem;
	--background-color: var(--document-resource-gray);
	background-color: var(--background-color);
	border: 2px solid var(--background-color);
}

.document-resource-folder-display--dragging-over {
	border-color: var(--verita-blue);
}

.document-resource-folder-display--loading__icon,
.document-resource-folder-display--loading__name {
	opacity: 0;
	pointer-events: none;
}

.document-resource-folder-display__options-button {
	--size: 2rem;
	width: var(--size);
	height: var(--size);
	border: none;
	border-radius: 50%;

	position: relative;
}

.document-resource-folder-display__options-button:hover,
.document-resource-folder-display__options-button:focus {
	background-color: #e3e2e4;
}

.document-resource-folder-display__options-button__icon-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.document-resource-folder-display__options-button__icon-container svg {
	display: block;
}

.document-resource-folder-display__name {
	font-size: 1.2rem;
	width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

	--internal-link---text-decoration: none;
}
