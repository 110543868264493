html {
	/* define theme CSS custom properties here */

	--general__main---horizontal-gutter: 1rem;
	--general__main---verital-gutter: 0.5rem;

	--general__tile---border-radius: 2.5rem;

	--header---element-spacing: 0.5rem;
	--header__bar---height: 3.5rem;
	--header__bar---margin-top: var(--general__main---verital-gutter);
	--header__bar__navigation-toolbar---width: calc(100vw - 2rem - var(--general__tile---border-radius));
	--header__bar__navigation-toolbar---height: 1.75rem;
	--header__drawer---width: 90vw;
	--header__side-bar---width: 13rem;
	--header__side-bar---full-width: calc(var(--header__side-bar---width) + var(--general__main---horizontal-gutter));
	/* --header---height is used by other elements that depend on the header, the header's height is set internally. */
	--header---height: 0px;

	--verita-blue: #0063ad;
	--verita-green: #6ab065;
	--verita-dark-blue: #102d68;
	--verita-light-blue: #d4dfed;

	--verita-colors-pink: #f19f9c;
	--verita-colors-green: #96cd3f;
	--verita-colors-yellow: #ecda4b;
	--verita-colors-teal: #84d0cc;
	--verita-colors-orange: #ff8a54;

	--light-blue: #f7f8fd;

	--general---theme-color: var(--verita-blue);
	--general---theme-text-color: #fff;

	--header---color: var(--general---text-color);
	--header---background-color: #fff;
	--footer---color: var(--general---theme-text-color);
	--footer---background-color: var(--general---theme-color);
	--general__header---color: var(--general---theme-text-color);
	--general__header---background-color: var(--general---theme-color);

	--heading--level-1---font-size: 2.5rem;

	--main---background-color: #eee;
	--main---padding-top: 0.75rem;

	--modal---border-radius: var(--general__tile---border-radius);
	--modal__header---padding: 0.5rem 1.5rem;
	--modal__footer---padding: 0.5rem 1.5rem 1rem;
	--modal__close-button---top: 1rem;
	--modal__close-button---right: 1rem;

	--section__body---padding: 1rem 1.5rem;

	--general--spaced---horizontal-spacing: 0.75rem;
	--general__field__input---border-radius: 1rem;

	--general__field__value---height: 2.25rem;
	--boolean-field__value---height: var(--general__field__value---height);
	--date-time-field__value---height: var(--general__field__value---height);
	--file-field__value---height: calc(var(--general__field__value---height) * 2.5);
	--multi-select-field__value---height: var(--general__field__value---height);
	--number-field__value---height: var(--general__field__value---height);
	--password-field__value---height: var(--general__field__value---height);
	--radio-field__value---height: var(--general__field__value---height);
	--select-field__value---height: var(--general__field__value---height);
	--text-field__value---height: var(--general__field__value---height);
	--field-set-array__value---height: var(--general__field__value---height);

	--heading--level-1---margin: 0 0 1rem;

	--general---font-family: "Baloo 2", sans-serif;
	font-family: var(--general---font-family);
	--heading---font-family: var(--general---font-family);
	--button---font-family: var(--general---font-family);
	--general__field__value---font-family: var(--general---font-family);
	--label---font-family: var(--general---font-family);
	--legend---font-family: var(--general---font-family);

	--general---line-height: 1.15;
	line-height: var(--general---line-height);

	--button--link--hover-focus---background-color: transparent;
}

button {
	line-height: var(--general---line-height);
}

h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1.62;
}

/* TODO fix */
.boolean-field__value__zLWuHlim,
.date-time-field__value__z4D8yLwB,
.multi-select-field__value__CIfGt2lH,
.number-field__value__JZu6EP-S,
.radio-field__value__17oZ-Nrr,
.select-field__value__ZWMerDsv,
.text-field__value__b3yncfAc {
	line-height: 1.4;
}

pre {
	font-family: var(--general---font-family);
}

button {
	font-family: var(--button---font-family);
}

body {
	--table__header---top: var(--header---height);

	color: var(--general---color);
	background-color: var(--main---background-color);
}

body.body--with-header {
	--header---height: calc(var(--header__bar---height) + var(--header__bar---margin-top));
	--pop-up---top: calc(var(--header---height) + 1rem);

	padding-top: var(--header---height);
}

body.body--with-header.body--with-navigation-toolbar {
	--header---height: calc(
		var(--header__bar---height) + var(--header__bar---margin-top) + var(--header__bar__navigation-toolbar---height) +
			var(--header---element-spacing)
	);
}

body.body--with-header-side-bar {
	--pop-up---right: calc(var(--header__side-bar---full-width) + 1rem);
	--header__bar__navigation-toolbar---width: calc(
		100vw - 2rem - var(--general__tile---border-radius) / 2 - var(--header__side-bar---full-width)
	);

	padding-right: var(--header__side-bar---full-width);
}

@media print {
	body,
	body.body--with-header,
	body.body--with-header.body--with-navigation-toolbar {
		--header---height: 0px;
	}
}

main {
	width: 100%;
	min-height: 100vh;
}

body.body--with-header main {
	min-height: calc(100vh - var(--header---height));
}

.scroll-target {
	scroll-margin-top: calc(var(--header---height) + var(--floating-elements--top---height, 0px));
	scroll-margin-bottom: var(--floating-elements--bottom---height, 0px);
}

.print-only {
	display: none;
}

@media print {
	.no-print {
		display: none;
	}

	.print-only {
		display: initial;
	}
}

.link--no-text-decoration {
	--internal-link---text-decoration: none;
	--internal-link--hover-focus---text-decoration: none;
	--internal-link--active---text-decoration: none;
	--external-link---text-decoration: none;
	--external-link--hover-focus---text-decoration: none;
	--external-link--active---text-decoration: none;
}

.no-scroll-bar {
	scrollbar-width: none;
	-ms-overflow-style: none;
}

.no-scroll-bar::-webkit-scrollbar {
	width: 0;
	height: 0;
}

/* Hex Core overrides */

.internal-link__iASmtu4R {
	width: auto;
}

.modal__close-confirmation__wAUtW8x2 p {
	margin-right: 1rem;
}
