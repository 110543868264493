.quick-list__tile__body {
	font-size: 1.2rem;

	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.quick-list__row {
	position: relative;
}

.quick-list__icon {
	margin-left: 0.25rem;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}
