.filter-button {
	white-space: nowrap;
}

.filter-button--campus {
	border-radius: 10rem;
}

.filter-button--home-room-section {
	font-size: 0.75rem;

	padding-top: 0.1rem;
	padding-bottom: 0.1rem;
}
