.link {
	--internal-link---color: #fff;
	--internal-link--hover-focus---color: #fff;
	--internal-link---text-decoration: none;
	background-color: var(--verita-blue);
	font-size: 3rem;

	width: 40%;
	height: 15rem;
	border-radius: var(--general__tile---border-radius);

	display: inline-block;
}
