.attendance-stat {
	background-color: #eee;

	min-width: 5rem;
	padding: 0.5rem;
	border-radius: 1rem;
}

.attendance-stats__label {
	font-size: 0.9rem;
}

.attendance-stats__value {
	font-size: 1.5rem;
}

.attendance-notes__button {
	padding: 0;
	border: none;
}

.attendance-notes__note {
	border: 1px solid #eee;
	border-radius: 1rem;
}

.attendance-notes__note__header {
	padding: 0.5rem 0.5rem 0;
}

.attendance-notes__note__body {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.attendance-notes__note__date {
	font-size: 0.9rem;
	color: #777;
}

.attendance-notes__note__footer {
	font-size: 0.85rem;

	padding: 0 0.5rem 0.5rem;
}
