.header__bar,
.header__drawer,
.header__side-bar {
	--internal-link---color: var(--header---color);
	--nav-link---color: var(--header---color);
	color: var(--header---color);
	background-color: var(--header---background-color);
}

.header__bar,
.header__drawer {
	--nav-link---text-decoration: none;
	--nav-link--hover-focus---text-decoration: none;
	--nav-link--active---text-decoration: none;
}

.header__bar {
	width: calc(100% - var(--general__main---horizontal-gutter) * 2);
	height: var(--header__bar---height);
	padding: 0.5rem 1.5rem;
	border-radius: var(--general__tile---border-radius);
	box-shadow: var(--general---box-shadow);
	margin: var(--header__bar---margin-top) auto 0;

	position: relative;
}

.header__bar__site-title {
	font-size: 1.75rem;
	--internal-link---text-decoration: none;
	--internal-link--hover-focus---text-decoration: none;
	--internal-link--active---text-decoration: none;

	width: fit-content;
	height: fit-content;
}

.header__bar__title-and-search {
	width: fit-content;
	position: relative;
}

.header__bar__navigation-toolbar-button-container {
	position: absolute;
	top: 50%;
	left: calc(100% + 1rem);
	transform: translateY(-50%);
}

.header__bar__global-search,
.header__bar__nav {
	display: none;
}

@media (min-width: 800px) {
	.header__bar__drawer-button {
		display: none;
	}

	.header__bar__global-search,
	.header__bar__nav {
		display: block;
	}

	.header__bar__navigation-toolbar-button-container {
		position: static;
		transform: none;
	}
}

.header__bar__navigation-toolbar {
	font-size: 0.9rem;
	--internal-link---color: var(--general---color);
	color: var(--general---color);
	background-color: var(--general---background-color);

	width: var(--header__bar__navigation-toolbar---width);
	height: var(--header__bar__navigation-toolbar---height);
	padding-right: 0.5rem;
	border-radius: var(--general__tile---border-radius);
	box-shadow: var(--general---box-shadow);

	position: absolute;
	bottom: 0;
	left: calc(var(--general__tile---border-radius) / 2);
	transform: translateY(calc(100% + var(--header---element-spacing)));
}

.header__bar__navigation-toolbar a {
	text-decoration: none;
}

.header__bar__navigation-toolbar__back-link {
	font-size: 1.25rem;
	text-align: center;

	height: 100%;
	width: 2rem;
	padding: 0 0.5rem;
	border-top-left-radius: var(--general__tile---border-radius);
	border-bottom-left-radius: var(--general__tile---border-radius);

	display: inline-flex;
	justify-content: center;
	align-items: center;
}

.header__bar__navigation-toolbar__back-link:hover,
.header__bar__navigation-toolbar__back-link:focus {
	background-color: #f2f2f2;
	text-decoration: none;
}

.header__bar__navigation-toolbar__back-link--placeholder:hover,
.header__bar__navigation-toolbar__back-link--placeholder:focus {
	background-color: transparent;
}

.header__bar__navigation-toolbar__bread-crumbs {
	white-space: nowrap;

	width: 100%;
	padding: 0.25rem 0;
}

.header__bar__navigation-toolbar__bread-crumbs::-webkit-scrollbar {
	display: none;
}

.header__drawer {
	width: var(--header__drawer---width);
}

.header__drawer__body {
	--header__drawer__body---h-padding: 1.5rem;
	padding: 2.5rem var(--header__drawer__body---h-padding);
}

.header__drawer__global-search {
	--global-search__simple__search-bar---width: calc(
		var(--header__drawer---width) - var(--header__drawer__body---h-padding) * 2
	);
}

.header__nav__link {
	--nav-link--location-active---color: #fff;
	--nav-link--active---color: var(--verita-blue);

	width: fit-content;
	padding: 0.5rem 1rem;
	border-radius: 2rem;

	display: block;
}

.header__nav__link:hover,
.header__nav__link:focus {
	background-color: #eee;
}

.header__nav__link--location-active,
.header__nav__link--location-active:hover,
.header__nav__link--location-active:focus {
	background-color: var(--verita-blue);
}

.header__nav__link__tooltip__label {
	white-space: nowrap;
}

.header__side-bar {
	width: var(--header__side-bar---width);
	height: calc(
		100vh -
			(
				var(--header__bar---height) + var(--header__bar---margin-top) + var(--header---element-spacing) +
					var(--general__main---verital-gutter)
			)
	);
	border: none;
	border-radius: var(--general__tile---border-radius);
	box-shadow: var(--general---box-shadow);
}

.header__side-bar__wrapper {
	margin-right: var(--general__main---horizontal-gutter);
	top: calc(var(--header__bar---height) + var(--header__bar---margin-top) + var(--header---element-spacing));
}
