.status {
	font-size: 0.9rem;

	padding: 0.25rem 0.5rem;
	border-radius: 0.5rem;
}

.status--approved {
	color: #fff;
	background-color: var(--success-color);
}

.status--pending {
	background-color: var(--warning-color);
}

.status--rejected {
	color: #fff;
	background-color: var(--danger-color);
}

.view-status {
	font-size: 0.9rem;
	height: 100%;
}

.view-status--new {
	color: var(--success-color);
}

.view-status--seen {
	color: #777;
}
