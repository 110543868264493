.enrollment-pricing-table {
	border: 1px solid #777;
	border-radius: 0.5rem;
	overflow: hidden; /* disables sticky header */
	--table__header---background-color: #f4f4f4;
}

.enrollment-pricing-table__header__row > * {
	border-bottom: 1px solid #eee;
}

.enrollment-pricing-table__body__row > :first-child {
	border-right: 1px solid #eee;
}

.enrollment-pricing-table__body__row > :last-child {
	font-weight: bold;
	border-left: 1px solid #eee;
}

.enrollment-pricing-table__footer__row {
	font-weight: bold;
}

.enrollment-pricing-table__footer__row > * {
	border-top: 1px solid #eee;
}

.enrollment-pricing-table__footer__row > :last-child {
	color: var(--success-color);
}
