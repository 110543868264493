.small-event-calendar__event-icon {
	background-color: transparent; /* overridden by style in JS for group. */

	--size: 0.5rem;
	width: var(--size);
	height: var(--size);
	border-radius: 50%;
}

.small-event-calendar__event-list-item__tooltip-container {
	width: 100%;
}

.small-event-calendar__event-list-item__tooltip {
	width: 90%;
	--tooltip---background-color: #fff;
	--tooltip---color: var(--general---color);
	--tooltip__header---color: var(--tooltip---color);
	--tooltip__body---color: var(--tooltip---color);
}

.small-event-calendar__event-list-item__tooltip__header {
	padding-top: 0.75rem;
	padding-bottom: 0;
}

.small-event-calendar__event-list-item__tooltip__body {
	padding-top: 0.25rem;
}

.small-event-calendar__event-list-item {
	width: 100%;
	padding: 0.25rem;
	border-left: 2px solid var(--event-color);

	position: relative;
	z-index: 0;
}

.small-event-calendar__event-list-item__background {
	opacity: 0.25;
	background-color: var(--event-color);

	width: 100%;
	height: 100%;
	border-top-right-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;

	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
}

.small-event-calendar__event-list-item__name,
.small-event-calendar__event-list-item__time {
	position: relative;
	z-index: 1;
}

.small-event-calendar__event-list-item__name {
	font-weight: bold;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

	width: 100%;
	margin-right: 0.5rem;
	display: inline-block;
}

.small-event-calendar__event-list-item__time {
	color: #444;
	white-space: nowrap;
}

.small-event-calendar__full-event-list {
	background-color: #fff;

	width: 100%;
	min-width: 0;
	height: 100%;
	overflow: hidden;

	position: absolute;
	top: 0;
	left: 0;
}

.small-event-calendar__full-event-list__list__wrapper {
	width: 100%;
	min-width: 0;
	height: 100%;
	--fade-height: 1rem;
	overflow: hidden;
	flex-grow: 1;

	position: relative;
}

.small-event-calendar__full-event-list__list__wrapper::after {
	content: "";

	background-image: linear-gradient(transparent, #fff);

	width: 100%;
	height: var(--fade-height);

	position: absolute;
	bottom: 0;
	left: 0;
}

.small-event-calendar__full-event-list__list {
	width: 100%;
	min-width: 0;
	height: 100%;
	overflow: hidden scroll;
	padding-bottom: var(--fade-height);
}
