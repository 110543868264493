.main-block {
	height: 100%;
	padding: 0.5rem;
	position: relative;
}

.your-daily-report {
	font-size: 1.25rem;
	position: relative;
	z-index: 1;
}

@media (min-width: 1110px) {
	.your-daily-report {
		font-size: 2rem;
	}
}

.updated-at {
	font-size: 0.75rem;
	opacity: 0.6;

	position: absolute;
	bottom: 0.25rem;
	left: 0.5rem;
	z-index: 1;
}

.tiles-background {
	--opacity: 0.05;
	opacity: var(--opacity);

	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;

	transition: opacity ease-out 150ms;
}

.main-block:hover .tiles-background {
	opacity: calc(1.5 * var(--opacity));
}

.tiles-background__tile {
	background-color: var(--verita-blue);
	border-radius: 0.5rem;
	box-shadow: var(--general---box-shadow);

	--hover-scale: 1.05;
	transition: transform ease-out 150ms;
}

.main-block:hover .tiles-background__tile {
	transform: scale(var(--hover-scale));
}

@media (min-width: 800px) {
	.tiles-background__tile {
		--hover-scale: 1.04;
	}
}

@media (min-width: 1000px) {
	.tiles-background__tile {
		--hover-scale: 1.03;
	}
}

@media (min-width: 1200px) {
	.tiles-background__tile {
		--hover-scale: 1.02;
	}
}

@media (min-width: 1400px) {
	.tiles-background__tile {
		--hover-scale: 1.01;
	}
}

.stat-button {
	padding: 0;
	border: none;
}

.stat-button__icon-container {
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 50%;
	box-shadow: var(--general---box-shadow);

	position: relative;

	transition: transform ease-out 150ms;
}

.stat-button:hover .stat-button__icon-container {
	transform: scale(1.05);
}

.stat-button__icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.stat-button__text {
	font-size: 1.5rem;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.view-full-report-button {
	width: 100%;
	border-color: var(--light-blue);
	border-width: 3px;
	border-radius: 5rem;
	transition: 200ms ease-out;
}

.view-full-report-button:hover {
	color: #fff;
	background-color: var(--verita-blue);
	border-color: var(--verita-blue);
}

.daily-quotation {
	background-color: #fff;

	padding: 0.25rem 0.5rem;
	border-radius: 1rem;

	position: relative;

	overflow: hidden;
}

.daily-quotation__body {
	font-size: 2rem;
	text-align: center;

	margin-bottom: 0;

	position: relative;
	z-index: 1;
}

.daily-quotation__attribution {
	color: #555;

	position: relative;
	z-index: 1;
}

.daily-quotation__background {
	width: 100%;
	height: 100%;

	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
}

.meal-menu__description {
	font-size: 1.1rem;
	text-align: center;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	line-clamp: 2;

	width: 100%;
	max-height: 100%;
	margin-bottom: 0;
}
