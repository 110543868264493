.timeline {
	padding-right: 0.75rem;
	border-right: 2px solid var(--verita-blue);
	margin-right: 5px;
}

.menu-bar {
	width: fit-content;
	padding: 0.25rem 0.5rem;

	position: absolute;
	top: 0.25rem;
	right: 2rem; /* TODO will need tweaking / dynamic handling */
	z-index: 91;
}

@keyframes spin {
	0% {
		transform: rotate(0turn);
	}

	100% {
		transform: rotate(1turn);
	}
}

.refresh-button--fetching .refresh-button__icon {
	animation: spin 350ms linear 0ms infinite;
}

.timeline__group:first-child .timeline__time-separator {
	scroll-margin: 0.5rem;
}

.timeline__time-separator {
	padding-top: 0.5rem;
	margin-top: 1rem;
	margin-bottom: 0.5rem;

	background-color: #fffd;
}

.timeline__time-separator--sticky {
	position: sticky;
	top: 0;
	z-index: 90;
}

.timeline__time-separator:first-child {
	margin-top: 0;
}

.timeline__time-separator + .timeline__time-separator {
	margin-top: 0;
	padding-top: 0;
}

.timeline__item {
	width: 100%;
	--padding-top: 1rem;
	padding-top: var(--padding-top);

	position: relative;
}

.timeline__item__point {
	background-color: var(--verita-blue);

	--size: 0.75rem;
	width: var(--size);
	height: var(--size);
	border: 2px solid #fff;
	border-radius: 50%;

	position: absolute;
	top: calc(0.3rem + var(--padding-top));
	right: -1.2rem;
}

.timeline__item__body-expand-container {
	width: 100%;
	padding: 0.5rem;
	margin-left: 0.5rem;
	cursor: pointer;
}

.timeline__item__body-expand-container:hover {
	background-color: #f4f4f4;
}

.timeline__item:not(:last-child) .timeline__item__body-expand-container {
	border-bottom: 1px solid #eee;
}

.timeline__item__body-container {
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.timeline__item__expand-button-container {
	padding: 0 0.5rem;
	margin-left: 0.75rem;

	transition: 200ms ease-out;
}

.timeline__item__body-expand-container:not(:hover) .timeline__item__expand-button-container {
	opacity: 0;
	transform: translateX(0.5rem);
}
