.create__notes {
	--text-field---width: 40.75rem;
}

.create__payment-line-items {
	--field-set-array---width: 40.75rem;
}

.create__payment-line-items__item {
	--number-field---width: 8rem;
	--select-field---width: 25rem;
}
