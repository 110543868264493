.position-wrapper {
	position: relative;

	overflow: hidden;
}

.horizontal-scrolling-container {
	height: fit-content;

	scroll-behavior: smooth;
	overflow: scroll hidden;
}

.position-wrapper:not(:hover) .scroll-button-container {
	opacity: 0;
}

.scroll-button-container {
	--fade-width: 1.25rem;
	--fade-padding: 1rem;
	--fade-direction: 90deg;

	background-image: linear-gradient(var(--fade-direction), transparent, var(--background-color) var(--fade-width));

	height: 100%;

	position: absolute;
	top: 0;
	z-index: 1;

	transition: 200ms ease-out;
}

.scroll-button-container--left {
	--fade-direction: 270deg;

	padding-right: var(--fade-padding);

	left: 0;
}

.scroll-button-container--right {
	padding-left: var(--fade-padding);

	right: 0;
}

.horizontal-scrolling-container--full-left .scroll-button-container--left,
.horizontal-scrolling-container--full-right .scroll-button-container--right {
	opacity: 0;
	pointer-events: none;
}

.scroll-button {
	width: 100%;
	height: 100%;
	padding: 0;
	border: none;
}

.scroll-button__column {
	height: 100%;
}

.scroll-button__icon-container {
	--size: 2.5rem;
	width: var(--size);
	height: var(--size);
	padding: 0;
	border-radius: 50%;

	position: relative;

	transition: 150ms ease-out;
}

.scroll-button:hover .scroll-button__icon-container {
	background-color: #0001;
}

.scroll-button__icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.scroll-button-container--left .scroll-button__icon {
	margin-right: 0.1rem;
}

.scroll-button-container--right .scroll-button__icon {
	margin-left: 0.1rem;
}
