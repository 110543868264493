.main-row {
	--main-row---margin-top: 0.75rem;
	margin-top: var(--main-row---margin-top);
}

.left-column {
	--left-column---max-width: 25rem;

	width: 30%;
	max-width: var(--left-column---max-width);
	flex-shrink: 0;
}

@keyframes left-sub-items-column {
	0% {
		transform: translateY(80vh);
	}

	100% {
		transform: translateY(0);
	}
}

.left-sub-items-column {
	min-height: 0;

	animation: left-sub-items-column 600ms ease 100ms both;
}
