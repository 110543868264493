.create-bulk__service-subscriptions {
	--field-set-array---width: 43rem;
	--field-set-array__value---padding: 0;
}

.create__fields-no-students {
	--field-set-array---width: 43rem;
}

.create__discounts {
	--field-set-array---width: 40.75rem;
}

.create__discounts__item {
	--number-field---width: 10rem;
}

.create__discounts__item {
	--text-field---width: 100%;
}
