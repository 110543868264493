.verita-small-calendar .react-calendar__navigation {
	margin-bottom: 0.1em;
}

.verita-small-calendar .react-calendar__navigation__label {
	font-size: 1.375rem;
	white-space: nowrap;
}

.verita-small-calendar .react-calendar__navigation__arrow {
	font-size: 1.75rem;
}

.verita-small-calendar--no-secondary-arrows .react-calendar__navigation__prev2-button,
.verita-small-calendar--no-secondary-arrows .react-calendar__navigation__next2-button {
	opacity: 0;
	pointer-events: none;
}

.verita-small-calendar .react-calendar__month-view__weekdays__weekday {
	font-size: 1rem;
	font-family: var(--general---font-family);
	text-transform: none;
}

.verita-small-calendar .react-calendar__month-view__days__day {
	font-size: 1rem;
}

.verita-small-calendar .react-calendar__month-view__days__day--weekend,
.verita-small-calendar .react-calendar__month-view__days__day {
	color: var(--general---color);
}

.verita-small-calendar .react-calendar__month-view__days__day--neighboringMonth {
	color: #888;
}

.verita-small-calendar .react-calendar__tile {
	border: 2px solid transparent;
}

.verita-small-calendar .react-calendar__tile:enabled:hover {
	background-color: #eee;
}

.verita-small-calendar .react-calendar__tile--active {
	background-color: transparent;
	border-color: var(--verita-blue);
}

.verita-small-calendar .react-calendar__tile--now {
	background-color: #0062ad3f;
}

.verita-small-calendar .react-calendar__tile--now:hover {
	background-color: #0062ad20;
}

.react-calendar__navigation button.react-calendar__navigation__label:disabled {
	/* override disabled look on label button */
	color: rgba(0, 0, 0, 0.847);
	background: none;
}
