.expanding-tile {
	position: relative;
	background-color: transparent;
}

.expanding-tile:hover {
	z-index: 10;
}

.expanding-tile__body {
	padding: 0;
}

.expanding-tile__body__content {
	background-color: var(--background-color);

	height: 100%;
	padding: var(--section__body---padding);
	border-radius: var(--general__tile---border-radius);

	position: relative;
	z-index: 2;
}

.expanding-tile__expand-button {
	color: var(--verita-blue);
	background-color: var(--background-color);

	--exposed-height: 2rem;
	--additional-height: 2.5rem;

	width: 100%;
	height: calc(var(--exposed-height) + var(--additional-height));
	padding: 0;
	padding-bottom: var(--additional-height);
	border: none;
	border-top-right-radius: var(--general__tile---border-radius);
	border-top-left-radius: var(--general__tile---border-radius);

	position: absolute;
	top: 0;
	right: 0;
	z-index: 0;

	transition: 200ms ease-out;
}

.expanding-tile__expand-button:not(:hover) .expanding-tile__expand-button {
	opacity: 0;
	pointer-events: none;
}

.expanding-tile:hover .expanding-tile__expand-button {
	transform: translateY(-2rem);
	box-shadow: var(--box-shadow, var(--general---box-shadow));
}
