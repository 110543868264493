.auth-page__main {
	background: linear-gradient(30deg, var(--verita-green), var(--verita-blue) 40%, var(--verita-dark-blue));

	display: flex;
	justify-content: center;
	align-items: center;
}

.auth-page__content-container {
	background-color: var(--general---background-color);

	width: fit-content;
	padding: 2rem 3rem;
	border-radius: 1rem;

	box-shadow: var(--general---box-shadow);
}
