.document-resource-display {
	--background-color: transparent;
	background-color: var(--background-color);

	border-radius: 0.5rem;

	user-select: none;
}

.document-resource-display--medium {
	--image-width: 5rem;
	width: calc(var(--image-width) + 1rem);
}

.document-resource-display--small {
	max-width: 10rem;
}

.document-resource-display--image {
	--background-color: #feedd8;
}

.document-resource-display--file {
	--background-color: #e5eaf6;
}

.document-resource-display--loading {
	--background-color: var(--document-resource-gray);
}

.document-resource-display--loading__image {
	width: var(--image-width);
	height: var(--image-width);
}

.document-resource-display--loading__icon,
.document-resource-display--loading__name {
	opacity: 0;
	pointer-events: none;
}

.document-resource-display__button {
	width: 100%;
	height: 100%;
	padding: 0.25rem 0.5rem;
	border: 1px solid var(--background-color);
	border-radius: 0.5rem;
}

.document-resource-display--resource.document-resource-display--small .document-resource-display__button {
	border-color: #eee;
}

.document-resource-display__column {
	width: 100%;
}

.document-resource-display__name {
	white-space: nowrap;
	text-overflow: ellipsis;

	max-width: 100%;
	overflow: hidden;
}

.resource-preview {
	width: 90vw;
	max-width: calc(100vw - 2rem);
	height: 95vh;
	max-height: none;

	overflow: hidden;

	--modal__body---padding: 1rem;
}

.resource-preview__display {
	width: 100%;
	height: 100%;
	border-radius: calc(var(--general__tile---border-radius) - var(--modal__body---padding));
}

/* Image Container */

.image-container {
	--size: var(--image-width);
	width: var(--size);
	height: var(--size);

	overflow: hidden;
}

.image-container img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.image-placeholder {
	color: #777;

	width: 100%;
	height: 100%;
	border-radius: 1rem;
}
