.note-modal {
	--label---font-weight: bold;
	--label---font-size: 1rem;
}

.note-type-button {
	--button---background-color: var(--light-blue);
	--button---border-width: 1px;
	--button---border-color: var(--verita-blue);
	--button---border-radius: 10rem;
	width: 100%;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;

	position: relative;
}

.note-type-button:disabled {
	--button---color: #999;
	--button---border-color: transparent;
}

.note-type-button__info-tooltip {
	position: absolute;
	top: 50%;
	right: 1rem;
	transform: translateY(-50%);
}
