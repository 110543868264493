.text-area-field {
	--text-field__value---height: 3.25rem;
}

.text-area {
	resize: none;
}

.text-area-display {
	font-family: var(--general---font-family);

	white-space: pre-wrap; /* css-3 */
	white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
	white-space: -pre-wrap; /* Opera 4-6 */
	white-space: -o-pre-wrap; /* Opera 7 */
	word-wrap: break-word; /* Internet Explorer 5.5+ */
}
