.verita-big-calendar {
	--border-radius: 2rem;
}

.verita-big-calendar .rbc-month-view,
.verita-big-calendar .rbc-time-view {
	border-radius: var(--border-radius);
}

.verita-big-calendar .rbc-month-row:last-child .rbc-day-bg:first-child {
	border-bottom-left-radius: var(--border-radius);
}

.verita-big-calendar .rbc-month-row:last-child .rbc-day-bg:last-child {
	border-bottom-right-radius: var(--border-radius);
}

.verita-big-calendar .rbc-time-header {
	border-top-left-radius: var(--border-radius);
	border-top-right-radius: var(--border-radius);
	overflow: hidden;
}

.verita-big-calendar .rbc-time-content {
	border-bottom-left-radius: var(--border-radius);
	border-bottom-right-radius: var(--border-radius);
}

.verita-big-calendar .rbc-agenda-table {
	border-top-left-radius: var(--border-radius);
	border-top-right-radius: var(--border-radius);
}

.verita-big-calendar .rbc-agenda-content {
	border-bottom-left-radius: var(--border-radius);
	border-bottom-right-radius: var(--border-radius);
}

.verita-big-calendar .rbc-event.rbc-selected,
.verita-big-calendar .rbc-day-slot .rbc-selected.rbc-background-event,
.verita-big-calendar .rbc-event,
.verita-big-calendar .rbc-day-slot .rbc-background-event {
	background-color: var(--verita-blue);
}

.verita-big-calendar .rbc-event {
	border: none;
}

.verita-big-calendar .rbc-toolbar button:active,
.verita-big-calendar .rbc-toolbar button.rbc-active {
	box-shadow: none;
}
