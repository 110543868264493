.view-status {
	font-size: 0.9rem;
	height: 100%;
}

.view-status--new {
	color: var(--success-color);
}

.view-status--seen {
	color: #777;
}
