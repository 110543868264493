.container {
	position: relative;
}

.expanding {
	width: 100%;
	height: var(--expanded-height);

	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 10;

	opacity: 0;
	transform: scaleY(0);
	transform-origin: bottom;
	transition: transform 250ms ease-out, opacity 100ms linear 150ms;
}

.expanding--expanded {
	opacity: 1;
	transform: scaleY(1);
	transition: transform 300ms ease-out, opacity 100ms linear;
}
