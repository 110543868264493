.hub__index-page__table__container {
	--hub__index-page__table---border: 1px solid #e8e8e8;

	--table__header---background-color: #fafafa;
	--table__header---border: var(--hub__index-page__table---border);

	--table__body---border: var(--hub__index-page__table---border);

	--table__row---padding: 0.25rem 1.25rem;
	--table__row--hover---background-color: #f8f8f8;

	--table__heading---font-size: 0.9rem;
	--table__heading---font-weight: normal;
	--table__heading---color: #777;
	--table__heading---padding: 0.5rem 0.75rem 0.6rem;

	--table__cell---font-size: 1.1rem;
	--table__cell---padding: 1rem 0.4rem;

	--table__toolbar---background-color: var(--general---background-color);
	--table__toolbar---padding: 0.75rem 1rem;
	--table__toolbar---border-radius: var(--general---border-radius);
	--table__toolbar---box-shadow: var(--general---box-shadow);
	--table__toolbar---top-cutoff: 15rem;
}

.hub__index-page__table__header {
	border-top-right-radius: var(--general---border-radius);
	border-top-left-radius: var(--general---border-radius);
}

.hub__index-page__table__body {
	border-top: none;
	border-bottom-right-radius: var(--general---border-radius);
	border-bottom-left-radius: var(--general---border-radius);
}

.hub__index-page__table__row:not(:last-child) {
	border-bottom: var(--hub__index-page__table---border);
}

.hub__index-page__table__row.hub__index-page__table__row--filler {
	border-bottom-color: transparent;
}

.hub__index-page__table a {
	text-decoration: none;
}

.hub__index-page__table a:hover,
.hub__index-page__table a:focus {
	text-decoration: underline;
}
