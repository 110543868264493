.campus-overview {
	position: relative;
}

.campus-overview__heading {
	color: var(--verita-blue);
	line-height: var(--general---line-height);
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.campus-overview__heading__icon {
	transition: 150ms ease-out;
}

.campus-overview__heading-link:not(:hover):not(:focus) .campus-overview__heading__icon {
	transform: translateX(-0.5rem);
	opacity: 0;
}

.campus-overview__stats {
	width: fit-content;
	list-style-type: none;
	padding-left: 4%;
	margin: 0;
}

.campus-overview__stat {
	font-size: 1.1rem;
	width: 100%;
}

.campus-overview__stat__button {
	font-size: 1.1rem;

	width: 100%;
	padding: 0rem 0.5rem;
	border: none;
}

.campus-overview__stat__button:not(:disabled):hover,
.campus-overview__stat__button:not(:disabled):focus {
	background-color: var(--primary-color--lighter);
}

.campus-overview__stat__button--agreement {
	font-weight: bold;
}

.campus-overview__stat__button:not(:hover):not(:focus) .campus-overview__stat__button__show-icon {
	opacity: 0;
}

.campus-overview__stat__button__show-icon-container {
	padding-left: 0.25rem;
	margin-left: auto;
}

.campus-overview__stat__button__show-icon {
	color: var(--primary-color);
}

.campus-overview__stat__number {
	color: var(--verita-blue);
	font-size: 1.3rem;
	font-weight: bold;
}

.campus-image-container {
	opacity: 0.4;
	width: fit-content;
	--v-padding: 0.5rem;
	height: calc(100% - var(--v-padding) * 2);
	position: absolute;
	bottom: var(--v-padding);
	right: 1rem;
	z-index: 0;
}

.campus-image-container img {
	width: auto;
	height: 100%;
}

.home-room-section-link {
	font-size: 1.3rem;
}
