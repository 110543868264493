.create__service-subscriptions,
.create__contract-installments {
	--field-set-array---width: 43rem;
	--field-set-array__value---padding: 0;
	width: 100%;
}

.payment-schedule-type {
	--radio-field---width: 100%;
}
