.upload-box {
	color: var(--verita-blue);

	width: 100%;
	padding: 1rem;
	border: 2px dashed #ddd;
	border-radius: var(--general__tile---border-radius);

	position: relative;
	z-index: 11;

	overflow: hidden;
}

.upload-box--dragging {
	border-style: solid;
	border-color: var(--verita-blue);
}

.upload-box > * {
	pointer-events: none;
}

.upload-box__background {
	background-color: var(--verita-blue);
	opacity: 0;

	width: 100%;
	height: 100%;

	position: absolute;
	top: 0;
	left: 0;
}

.upload-box--dragging .upload-box__background {
	opacity: 0.15;
}

.upload-box__full-screen-overlay {
	background-color: #fffa;
	opacity: 0;
	visibility: hidden;

	width: 100vw;
	height: 100vh;

	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;

	transition: 150ms;
}

.upload-box__full-screen-overlay--dragging {
	opacity: 1;
	visibility: visible;
}

.upload-box__uploading-text {
	font-size: 0.9rem;
}
