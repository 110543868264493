.new-general-note-button {
	background-color: #f3f2e9;
	width: 8rem;
	height: 7rem;
	border: 2px dashed #888;
	border-radius: 1rem;
}

.new-general-note-button:hover,
.new-general-note-button:focus {
	border-color: #222;
}

.new-general-note-button__icon {
	color: #888;
}

.new-general-note-button:hover .new-general-note-button__icon,
.new-general-note-button:focus .new-general-note-button__icon {
	color: #222;
}
