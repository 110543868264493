.general-info-section {
	position: relative;
	margin-left: 0.25rem;

	--external-link---text-decoration: none;
	--internal-link---text-decoration: none;
}

.general-info-section--overview-page {
	height: 100%;
}

.general-info-section--other-pages {
	margin-bottom: 0.75rem;
}

.general-info-section__header {
	color: var(--verita-blue);

	padding: 0.75rem;

	--profile-image--full---image-size: 9rem;
	--profile-image--small---image-size: 7rem;
}

.general-info-section__header--overview-page {
	padding-bottom: 0.25rem;
}

.general-info-section__header__title {
	--title---height: 3.25rem;
}

.general-info-section__header__title__row {
	height: var(--title---height);
	padding-right: 0.5rem;
}

.student-person-icon,
.student-person-icon__image-container {
	transition: 400ms ease;
}

.general-info-section__body {
	padding-top: 0;
}

.student-person-icon {
	position: absolute;
	top: -1rem;
	left: -1rem;
}

.general-info-section__item {
	background-color: var(--light-blue);

	border-radius: 1rem;
}

.general-info-section__item__label,
.general-info-section__item__value {
	padding: 0.2rem 0.4rem;
}

.general-info-section__item__label {
	font-size: 0.9rem;
}

.general-info-section__item__value,
.general-info-section__button--link {
	font-size: 1.1rem;
}

.general-info-section__item__value {
	text-align: right;
}

.general-info-section__button--link {
	--button---color: var(--general---color);
	padding: 0;
	border: none;
}

.student-birthday {
	color: var(--verita-colors-orange);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.contact {
	--internal-link---text-decoration: none;
	--external-link---text-decoration: none;
}

.student-document-display {
	background-color: transparent;

	max-width: 10rem;
	padding: 0.25rem 0.5rem;
	border-radius: 0.5rem;

	user-select: none;
}

.student-document-display--image {
	background-color: #feedd8;
}

.student-document-display--file {
	background-color: #e5eaf6;
}
