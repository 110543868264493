.verita-small-calendar {
	border: none;
}

.verita-small-calendar__tile {
	padding: 0.25rem;
	border-radius: 10rem;
}

.verita-small-calendar__tile:disabled {
	color: #aaa;
	background-color: #fff;
}
