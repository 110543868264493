.note-form {
	--general__field---width: 25rem;
	--boolean-field---width: var(--general__field---width);
	--date-time-field---width: var(--general__field---width);
	--file-field---width: var(--general__field---width);
	--multi-select-field---width: var(--general__field---width);
	--number-field---width: var(--general__field---width);
	--password-field---width: var(--general__field---width);
	--radio-field---width: var(--general__field---width);
	--select-field---width: var(--general__field---width);
	--text-field---width: var(--general__field---width);
	--field-set-array---width: var(--general__field---width);

	--legend---color: var(--general---color);
	--label---font-size: 1.1rem;
	--label---color: var(--general---color);
}

.note-form legend,
.note-form label {
	font-size: 1.1rem;
}
