.quick-actions__link,
.quick-actions__button {
	background-color: var(--verita-blue);
	white-space: nowrap;

	border-radius: 10rem;
}

.quick-actions__link {
	--internal-link---color: #fff;
	color: var(--internal-link---color);

	padding: var(--button--medium---padding);
	border: var(--button--medium---border-width, 0) solid var(--verita-blue);
}

.quick-actions__link:hover,
.quick-actions__link:focus,
.quick-actions__button:hover,
.quick-actions__button:focus {
	color: #fff;
	background-color: var(--verita-blue);
	border-color: var(--verita-green);
}

.hub-link {
	color: #fff;
	background-color: #0063adbb;

	padding: 0.25rem 0.4rem;
	--border-width: 2px;
	border: var(--border-width) solid transparent;
	border-radius: 0.75rem;
	background-size: calc(100% + var(--border-width) * 2) calc(100% + var(--border-width) * 2);
	background-position: calc(-1 * var(--border-width)) calc(-1 * var(--border-width));

	transition: transform 200ms ease-out;
}

.hub-link:hover,
.hub-link:focus,
.hub-link:active {
	color: #fff;
	border-color: var(--verita-green);
}

.hub-link__text {
	color: #fff;
	text-align: center;

	width: 10rem;
	height: 6rem;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	transition: transform 150ms ease-out;
}

.hub-link:hover .hub-link__text,
.hub-link:focus .hub-link__text,
.hub-link:active .hub-link__text {
	transform: scale(1.1);
}
