.contract-installment {
	--color: var(--general---color);
	color: var(--color);

	padding: 0.1rem 0.2rem;
	border: 1px solid var(--color);
	border-radius: 0.25rem;
}

.contract-installment--future {
	--color: #777;
}

.contract-installment__year {
	font-size: 0.8rem;
}

.billing-item-list,
.billing-item-list .billing-item-list {
	list-style-type: none !important; /* TODO hex-core update styles for list */
	padding-left: 1rem;
	margin-top: 0;
	width: fit-content;
}

.billing-item-list .billing-item-list {
	width: 100%;
}

.billing-item-list--compact {
	padding: 0;
	margin: 0;
}

.billing-item-button {
	text-align: start;

	width: 100%;
	padding: 0rem 0.5rem;
}

.billing-item-button:not(:disabled):hover,
.billing-item-button:not(:disabled):focus {
	background-color: var(--primary-color--lighter);
}

.billing-item-button--agreement {
	font-weight: bold;
}

.billing-item-button:not(:hover):not(:focus) .billing-item-button__show-icon {
	opacity: 0;
}

.billing-item-button__show-icon-container {
	padding-left: 0.25rem;
	margin-left: auto;
}

.billing-item-button__show-icon {
	color: var(--primary-color);
}

.contract-billing-item__status {
	font-size: 0.9rem;
	font-weight: normal;
}

.contract-billing-item__status--draft {
	color: #777;
}

.contract-billing-item__status--void {
	color: var(--danger-color);
}

.contract-billing-item__status--complete {
	color: var(--success-color);
}
