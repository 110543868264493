.footer__bar {
	--internal-link---color: var(--footer---color);
	--nav-link---color: var(--footer---color);
	color: var(--footer---color);
	background-color: var(--footer---background-color);

	padding: 1.5rem 2rem 3rem;
}

.footer__bar__site-title {
	font-size: 1.25rem;

	width: fit-content;
	height: fit-content;
}

.footer__bar__version-field {
	font-size: 0.9rem;
	align-self: flex-end;
}

.footer__bar__version-number {
	font-family: monospace;
}
