/* Announcements */

.gradient-border {
	--border-width: 0.25rem;
	--border-radius: 2.5rem;

	background-image: linear-gradient(30deg, var(--verita-green) 20%, var(--verita-blue) 90%, var(--verita-dark-blue));
	width: 100%;
	padding: var(--border-width);
	border-radius: var(--border-radius);
}

.gradient-border__content {
	background-color: #fff;
	width: 100%;
	border-radius: calc(var(--border-radius) - var(--border-width));
}
