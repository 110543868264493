.course-section-lesson-plans {
	padding: 0.25rem;
	border: 1px solid var(--verita-blue);
	border-radius: 0.5rem;
}

.course-section-lesson-plans__name {
	max-width: 8.5rem;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.lesson-plan-day-icon {
	--size: 1.1rem;

	font-size: calc(0.8 * var(--size));

	width: var(--size);
	height: var(--size);
	border-radius: 50%;
	border: 1px solid var(--verita-blue);

	position: relative;
}

.lesson-plan-day-icon--has-lesson-plan {
	color: #fff;
	background-color: var(--success-color);
	border-color: var(--success-color);
}

.lesson-plan-day-icon__text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
